<template>
  <div>
    <!--  Page Header  -->
    <div class="page-header d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <router-link :to="{ name: 'credit_auth' }">
          <button class="btn btn-control">
            <img src="@/assets/icons/bold-icon previous.svg" alt="Return back">
          </button>
        </router-link>
        <h3 class="page-header-title ms-2">
          Signed Document
        </h3>
      </div>
    </div>

    <!--  Page Content  -->
    <div class="page-content p-0">
      <!--  Page Preload  -->
      <div class="text-center mt-5" v-if="ui.loading">
        <div class="spinner-border mt-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div class="row w-100 m-auto" v-else>
        <div class="col-12 col-md-8 border-right-light">
          <!--  Docs preview  -->
          <vue-html2pdf
            ref="html2Pdf"
            :show-layout="false"
            :enable-download="true"
            :float-layout="false"
            :manual-pagination="false"
            :paginate-elements-by-height="1400"
            :pdf-quality="2"
            :preview-modal="false"
            :html-to-pdf-options="options">
            <section slot="pdf-content">
              <div id="pdf-generate">
                <credit-auth-preview :docsData="docData"/>
              </div>
            </section>
          </vue-html2pdf>
        </div>
        <div class="col-12 col-md-4 pt-4 pe-4 ps-4">

          <!--  Success alert for request  -->
          <div class="col-12 request-done">
            Signed on {{ this.formatDate(docData.documentSignedOn, 'ddd h:ss A, MMM D YYYY') }}
          </div>

          <div>
            <div class="col-title d-flex align-items-center">
              <span>Participant Details</span>
            </div>

            <div class="mt-3">
              <div class="table">
                <table class="table">
                  <tbody>
                  <tr>
                    <td>First Name</td>
                    <td>{{ docData.firstName }}</td>
                  </tr>
                  <tr>
                    <td>Last Name</td>
                    <td>{{docData.lastName }}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>{{ docData.address }}</td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td>{{docData.city }}</td>
                  </tr>
                  <tr>
                    <td>{{ docData.state }}</td>
                    <td>{{ docData.zip }}</td>
                  </tr>
                  <tr>
                    <td>Email Address</td>
                    <td>{{ docData.email }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <base-button title="Download (PDF)" action="secondary" @click-btn="downloadDoc" class="w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import BaseButton from '../../../components/BaseButton.vue';
import CreditAuthPreview from "../preview/CreditAuthPreview";

export default {
  name: 'CreditAuthSignedDocument',
  components: {CreditAuthPreview, VueHtml2pdf, BaseButton},
  data() {
    return {
      ui: {
        loading: false
      },
      docData: {
        fullName: null,
        firstName: null,
        lastName: null,
        email: null,
        status: null,
        requestSentOn: null,
        documentSignedOn: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        signature: null
      },
      options: {
        margin: 0,
        filename: 'e-sing.pdf',
        enableLinks: true,
        jsPDF: {
          format: 'letter'
        }
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true
      this.$http.get(`/api/v1/credit-auth/details/${this.$route.params.uuid}`)
        .then(response => {
          this.docData = response.data.doc
          this.ui.loading = false
        })
        .catch()
    },
    downloadDoc() {
      this.options.filename = `E-sign ${this.docData.fullName}.pdf`
      this.$refs.html2Pdf.generatePdf()
    },
  },
  beforeMount() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.border-right-light {
  border-right: 1px solid #e7e8e8;
  @media screen and (max-width: 568px) {
    border: none;
  }
}
.col-title {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
}
.form-group {
  label {
    color: rgba(0,0,0,.7);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
  }
  input {
    height: 36px;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
    background-color: #FFFFFF;
  }
}
.request-done {
  padding: 11px 18px 11px 18px;
  margin-bottom: 24px;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  border: 1px solid rgba(0,215,104,0.4);
  border-radius: 4px;
  background-color: rgba(0,215,104,0.1);
}
.table {
  tr {
    td {
      height: 36px;
      padding: 10px 12px;
      &:first-child {
        color: rgba(0,0,0,.7);
        font-size: 13px;
        letter-spacing: 0;
        line-height: 18px;
        font-weight: normal;
      }
      color: #000000;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 18px;
      font-weight: normal;
    }
  }
}
button {
  &.btn-outline-grey {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 48px;
    width: fit-content;
    padding: 0 21.5px;
    color: rgba(0,0,0,.7);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    border-radius: 8px;
    border: 1px solid #8A8A8A;
    transition: all .3s;
    &:hover {
      background: #eeeeee;
    }
  }
}

#pdf-generate {
  max-width: 791px;
  width: 100%;
  //height: 1000px;
}

.vue-html2pdf::v-deep {
  .content-wrapper {
    max-width: 800px;
    width: 100% !important;
    margin-top: -30px;
    //margin-bottom: -30px;
  }
}
</style>
